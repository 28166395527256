import React from 'react';
import { Helmet } from 'react-helmet';
// import faviconImage from './path/to'


export const HelmetInfo = () => {
    const settingData = JSON.parse(localStorage.getItem('settingData'));
    // console.log("settingData", settingData.small_logo)
    return (
        <div>
            <Helmet>
                {/* <link rel="icon" href={faviconImage} type="image/x-icon" /> */}
                <link rel="shortcut icon" href={settingData?.small_logo} type="image/x-icon" />
            </Helmet>
            {/* Your component content goes here */}
        </div>
    );
}
