const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
}

const weekOffRosterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DATA_WEEK_OF_ROSTER":
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
            }
        case "CREATE_WEEK_OF_ROSTER":
            return { ...state }
        case "UPDATE_WEEK_OF_ROSTER":
            return { ...state }

        default:
            return { ...state }
    }
}
export default weekOffRosterReducer
