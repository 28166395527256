const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityManagerData: [],
    triggerData: [],
}

const tnCMasterReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_TNC':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'CREATE_TNC':
            return { ...state }
        case 'UPDATE_TNC':
            return { ...state }
        case "GET_TRIGGER_DATA":
            return {
                triggerData: action?.triggerData
            }
        default:
            return { ...state }
    }
}
export default tnCMasterReducer
