const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData: [],
    branchListData: [],
    userDataList: [],

}

const amc2ReportData = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_AMC_REPORT_FOR_AMC':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                statusCounts: action.statusCounts
            }
        case 'GET_CITY_LIST_FOR_AMC':
            return {
                ...state,
                cityListData: action.cityListData
            }
        case 'GET_USER_LIST_FOR_AMC':
            return {
                ...state,
                userDataList: action.userDataList
            }
        case 'GET_BRANCH_LIST_FOR_AMC':
            return {
                ...state,
                branchListData: action.branchListData
            }


        default:
            return { ...state }
    }
}
export default amc2ReportData
