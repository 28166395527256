// import crypto from 'crypto';
// // Assuming you have a function to generate a random Initialization Vector (IV)
// // If not, you can use a simple function like this:


// export const encryptRequestMiddleware = async (data) => {
//   try {

//     console.log("data", data)
//     const secretKey = "2da1320458c4aca488f9b2f09608581196b9fa0f0d3ac736f15ebe8aac970bb9"//process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY;
//     const iv = await crypto.randomBytes(16); // Generate a random IV
//     console.log("iv", iv)
//     const cipher = await crypto.createCipheriv('aes-256-cbc', secretKey, iv);
  
//     let encrypted = cipher.update(data, 'utf8'); // Assuming data is a UTF-8 encoded string
//     encrypted = Buffer.concat([encrypted, cipher.final()]);
  
//     // Combine the IV and the encrypted data
//     const encryptedDataStr = `${iv.toString('hex')}:${encrypted.toString('hex')}`;
//     return encryptedDataStr;
//   } catch (error) {
//     console.error('Encryption error:', error);
//     throw error; // Rethrow the caught error to allow further handling upstream
//   }
// };


import crypto from 'crypto';

export const encryptRequestMiddleware = async (data) => {
  try {
    console.log("data", data);
    const secretKey = "2da1320458c4aca488f9b2f09608581196b9fa0f0d3ac736f15ebe8aac970bb9"; // Hardcoded for demonstration
    const iv = crypto.randomBytes(16); // Generate a random IV
    console.log("iv", iv);

    // Convert the secretKey string to a Buffer using hex encoding
    const secretKeyBuffer = Buffer.from(secretKey, 'hex');

    // Now pass the Buffer to createCipheriv
    const cipher = crypto.createCipheriv('aes-256-cbc', secretKeyBuffer, iv);

    let encrypted = cipher.update(JSON.stringify(data), 'utf8'); // Assuming data is a UTF-8 encoded string
    encrypted = Buffer.concat([encrypted, cipher.final()]);

    // Combine the IV and the encrypted data
    const encryptedDataStr = `${iv.toString('hex')}:${encrypted.toString('hex')}`;
    return encryptedDataStr;
  } catch (error) {
    console.error('Encryption error:', error);
    throw error; // Rethrow the caught error to allow further handling upstream
  }
};
