const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    settingInformation:[]
    }
  
  const settingData = (state = initialState, action) => {
    switch (action.type) {
   
        case 'GET_DATA_SETTING':
          return { 
            ...state,
            settingInformation: action.data
          }
      default:
        return { ...state }
    }
  }
  export default settingData
  