const initialState = {
    data: [],
    total: 1,
    params: {},
    isFirstCall:false,
    allData: []
  }
  
  const leadSourceMasterData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_SOURCE_REMARK':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
          isFirstCall:action.isFirstCall
        }
      case 'CREATE_LEAD_SOURCE':
        return { ...state }
      case 'UPDATE_LEAD_SOURCE':
        return { ...state }
      default:
        return { ...state }
    }
  }
  
  export default leadSourceMasterData
  