const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    expenseData: [],
};

const manageExpensesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DATA_EXPENSES":
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
            };
        case "CREATE_EXPENSES":
            return { ...state };
        case "UPDATE_EXPENSES":
            return { ...state };
        case "GET_EXPENSES_BY_TYPE":
            return {
                ...state,
                expenseData: action.expenseData,
            };

        default:
            return { ...state };
    }
};
export default manageExpensesReducer;
