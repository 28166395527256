const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    // cityListData: [],
    jobReportDataByID: [],
    // materialAndSpecificationInfoById: []

}

const dcSummaryReportReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DC_SUMMARY':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
            }

        case 'GET_SUMMARY_BY_ID':
            return {
                ...state,
                jobReportDataByID: action.jobReportDataByID
            }


        default:
            return { ...state }
    }
}
export default dcSummaryReportReducer
