const initialState = {
    data: [],
    total: 1,
    params: {},
    isFirstCall:false,
    allData: []
  }
  
  const lostReasonMasterData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_LOST_REASON':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
          isFirstCall:action.isFirstCall
        }
      case 'CREATE_LOST_REASON':
        return { ...state }
      case 'UPDATE_REASON_LOST':
        return { ...state }
      default:
        return { ...state }
    }
  }
  
  export default lostReasonMasterData
  