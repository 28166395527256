const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  userData: [],
  trackingData: []

}

const userTrackingData = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_ACTIVE_USER':
      return {
        ...state,
        userData: action.data,
      }
    case 'TRACKING_USER':
      return {
        ...state,
        trackingData: action.data, 
      };


    default:
      return { ...state }
  }
}
export default userTrackingData
