const initialState = {
    data: [],
}

const hrSettingReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_HR_SETTING':
            return {
                ...state,
                data: action.data,
            }
        case 'CREATE_HR_SETTING':
            return { ...state }
        default:
            return { ...state }
    }
}
export default hrSettingReducer
