const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
};

const areaMasterData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_AREA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "CREATE_AREA":
      return { ...state };
    case "UPDATE_AREA":
      return { ...state };
    // case "GET_BRANCH_LIST":
    //   return { ...state };
      case 'GET_BRANCH_LIST':
        return { 
          ...state,
          branchList: action.data
        }
    default:
      return { ...state };
  }
};

export default areaMasterData;
