const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    // cityListData: [],
    // jobReportDataByID: [],
    jobNumberData: [],
    // materialAndSpecificationInfoById: []

}

const listingDCReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DC_LIST':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
            }    
        case 'GET_JOB_NUMBER':
            return {
                ...state,
                jobNumberData: action.jobNumberData
            }


        default:
            return { ...state }
    }
}
export default listingDCReducer
