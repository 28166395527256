const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: []
  }
  
  const componentMasterData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DATA_COMPONENT':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CREATE_COMPONENT':
        return { ...state }
      case 'UPDATE_COMPONENT':
        return { ...state }
      case 'GET_DATA_PASSENGER':
        return { 
          ...state,
          passengerData: action.passengerData
      }
      case 'GET_DATA_MACHINE_MASTER':
        return { 
          ...state,
          machineData: action.machineData
      }
      default:
        return { ...state }
    }
  }
  
  export default componentMasterData
  