const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    userData: [],


}

const leadsData = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LEADS_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                passengerData: []
            }
        case 'CREATE_LEADS':
            return { ...state }
        case 'UPDATE_LEADS':
            return { ...state }
        case 'GET_USER':
            return {
                ...state,
                userData: action.userData
            }
        case 'GET_DATA_EXCEL':
            return { ...state }
        case "IMPORT_LEAD_FORM_EXCEL":
            return { ...state };
        default:
            return { ...state }
    }
}

export default leadsData
