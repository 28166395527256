const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  cityListData: [],
  jobReportDataByID: [],
  materialAndSpecificationInfoById: [],
  trackData: [],
  branchListData: []

}

const jobReportData = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_JOB_REPORT':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        statusCounts: action.statusCounts
      }
    case 'GET_CITY_LIST':
      return {
        ...state,
        cityListData: action.cityListData
      }
    case 'GET_JOB_REPORT_DATA':
      return {
        ...state,
        jobReportDataByID: action.jobReportDataByID
      }
    case 'GET_P_TRACKING':
      return {
        ...state,
        trackData: action?.trackData
      }

    case 'GET_MATERIAL_AND_SPECIFICATION_DATA':
      return {
        ...state,
        materialAndSpecificationInfoById: action.data
      }
    case 'GET_BRANCH_LIST_JOB':
      return {
        ...state,
        branchListData: action?.branchListData
      }

    default:
      return { ...state }
  }
}
export default jobReportData
