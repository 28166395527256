const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
}

const holidayReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_HOLIDAY':

      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_HOLIDAY':

      return { ...state }
    case 'UPDATE_HOLIDAY':
      return { ...state }
    case 'DELETE_HOLIDAY':
      return { ...state }

    default:
      return { ...state }
  }
}
export default holidayReducer
