const initialState = {
    data: [],
    total: 1,
    params: {},
    userData: [],
    allData: [],
    historyData: [],
};

const monthlyAttendanceReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA_MONTHLY_ATTENDANCE_REPORT':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
            }
        case 'GET_ACTIVE_USER':
            return {
                ...state,
                userData: action.userData
            }
        case "UPDATE_ATTENDANCE":
            return { ...state };
        default:
            return { ...state }
    }
}
export default monthlyAttendanceReportReducer