// AuthContext.js
import { useEffect, useState, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDNSDataByUrl } from "../../redux/actions/superAdminConfig";
import { firebase, auth } from "../../base";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const localStorageDNSData = JSON.parse(localStorage.getItem("dnsData"));
  const localStorageUserData = JSON.parse(localStorage.getItem("userData"));
  const [dnsData, setDnsData] = useState(localStorageDNSData || []);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const store = useSelector(
    (state) => state?.superAdminConfigreducer?.data?.company_data
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getDNSDataByUrl(localStorageUserData?._id, setLoading));
      } catch (error) {
        console.error("Error fetching DNS data:", error);
      }
    };

    //If current user then call fetchData().
    if (firebase.auth().currentUser !== null) {
      fetchData();
    }

    // Call action on every 1 hour.
    const fetchDataInterval = setInterval(fetchData, 60 * 60 * 1000);

    // Clean up interval on component unmount
    return () => clearInterval(fetchDataInterval);
  }, [dispatch]);

  useEffect(() => {
    if (store && firebase.auth().currentUser !== null) {
      setDnsData(store);
      localStorage.setItem("dnsData", JSON.stringify(store));
    }
  }, [store]);

  return (
    <AuthContext.Provider value={{ dnsData, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
