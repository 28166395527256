const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    materialCategoryData:[],
    machineData:[]

  }
  
  const materialMasterData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DATA_MATERIAL':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CREATE_MATERIAL':
        return { ...state }
      case 'UPDATE_MATERIAL':
        return { ...state }
        case 'GET_DATA_Material_Category':
            return { 
              ...state,
              materialCategoryData: action.materialCategoryData
            }
            case 'GET_DATA_MACHINE_MASTER':
              return { 
                ...state,
                machineData: action.machineData
              }
      default:
        return { ...state }
    }
  }
  
  export default materialMasterData
  