const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],

}

const pmCheckPointReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PM_CHECKPOINT_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                breakDownCategoryData: []
            }
        case 'CREATE_PM_CHECKPOINT':
            return { ...state }
        case 'UPDATE_PM_CHECKPOINT':
            return { ...state }

        default:
            return { ...state }
    }
}

export default pmCheckPointReducer
