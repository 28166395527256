import firebase from 'firebase/app';
import 'firebase/auth';
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIB_API_KEY,
  authDomain:process.env.REACT_APP_FIB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIB_APP_ID,
};
firebase.initializeApp(firebaseConfig)
 const auth = firebase.auth();
 export {auth, firebase}