const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  totalCreditData: []

}

const dashboardData = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_TOTAL_ENQUIRES':
      return {
        ...state,
        data: action.data,
      }
    case 'GET_TOTAL_JOBS':
      return {
        ...state,
        totalJobs: action.data,
      }
    case 'GET_TOTAL_PMS':
      return {
        ...state,
        totalPMs: action.data,
      }
    case 'GET_TOTAL_BREAKDOWN':
      return {
        ...state,
        totalBreakdowns: action.data,
      }
    case 'GET_TOTAL_CREDITS':
      return {
        ...state,
        totalCreditData: action.data,
      }
    default:
      return { ...state }
  }
}
export default dashboardData
