
import crypto from 'crypto';

export const decryptResponseMiddleware = (encryptedData) => {
  try {
    // Ensure the encryptedData is in the correct format
    // if (!encryptedData?.includes(':')) {
    //   throw new Error("Invalid encrypted data format");
    // }

    if (typeof encryptedData !== 'string' || !encryptedData.includes(':')) {
      throw new Error("Invalid encrypted data format");
    }
    
    // Retrieve the encryption key from environment variables
    const key =  process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY;
    if (!key) {
      throw new Error("Encryption key is not set");
    }

    // Ensure the key is a hexadecimal string
    const secretKey = Buffer.from(key, 'hex');

    const [ivHex, encryptedText] = encryptedData.split(':');
    const iv = Buffer.from(ivHex, 'hex');

    // Ensure the algorithm and mode match those used during encryption
    const decipher = crypto.createDecipheriv('aes-256-cbc', secretKey, iv);

    let decrypted = decipher.update(encryptedText, 'hex', 'utf8');
    decrypted += decipher.final('utf8');

    // Check for errors during decryption
    if (!decrypted) {
      throw new Error("Decryption failed: No decrypted text received.");
    }

    // Parse the decrypted data as JSON
    const decryptedData = JSON.parse(decrypted);

    return decryptedData;
  } catch (error) {
    console.error('Decryption error:', error);
    throw error; // Rethrow the caught error to allow further handling upstream
  }
};
