const initialState = {
    data: {},

    }
  
  const InfoData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_INFORMATION':
        return {
          ...state,
          data: action.data,

        }
       
      default:
        return { ...state }
    }
  }
  export default InfoData
  